import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={15} height={11} viewBox="0 0 15 11" {...props}>
      <path
        d="M4.69 6.828l.296.193c.32.214.58.38.78.5.2.119.465.24.797.365s.642.187.929.187h.016c.287 0 .597-.062.929-.187a5.02 5.02 0 00.797-.366c.2-.119.46-.285.78-.499l.297-.192 4.284 3.87c-.25.2-.545.301-.885.301H1.29c-.34 0-.634-.101-.885-.302zM0 3.414c.24.257.514.484.824.683a282.72 282.72 0 012.825 1.87L0 9.483zm15 0v6.069l-3.649-3.515c.778-.523 1.722-1.146 2.833-1.87.31-.205.582-.433.816-.684zM13.66 0c.363 0 .677.12.942.358.265.239.398.526.398.861 0 .402-.137.785-.41 1.15a3.819 3.819 0 01-1.021.938 7958.365 7958.365 0 00-4.043 2.558l-.23.151a9.1 9.1 0 01-.887.537c-.171.092-.331.16-.482.206a1.45 1.45 0 01-.419.069h-.016a1.45 1.45 0 01-.419-.069 2.661 2.661 0 01-.481-.206 8.697 8.697 0 01-.435-.247c-.12-.074-.271-.17-.452-.29l-.231-.151-.125-.081-.845-.538C4.119 5 3.67 4.718 3.156 4.393 2.2 3.79 1.629 3.429 1.44 3.307a3.998 3.998 0 01-.98-.88C.153 2.053 0 1.707 0 1.387 0 .99.116.66.347.397.58.131.91 0 1.34 0z"
        fill="#157B58"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
