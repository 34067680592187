import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import './Header.scss'
import burger from '../../assets/svg/burger.svg'
import searchIcon from '../../assets/svg/search.svg'
import Logo from '../Logo/Logo'

const Header = (props: any): JSX.Element => {
  const linkData = useStaticQuery(graphql`
    query headerSocialLinks {
      socialMediaLinks {
        links {
          id
          link
          category
          name
        }
      }
    }
  `)

  const socialMediaList = ['facebook', 'instagram', 'youtube', 'twitter']
  const socialLinks =
    linkData && linkData.socialMediaLinks
      ? linkData.socialMediaLinks.links
          .filter((x: any) => {
            return !x.name.includes('Career')
          })
          .filter((x: any) => {
            return socialMediaList.includes(x.name.toLowerCase())
          })
      : []

  const [solidBG, setSolidBG] = useState<boolean>(false)

  const watchScroll = (): void => {
    let bs1: any = document.querySelector('.menu-base-details')
    let bs2: any = document.querySelector('.property-base-menu')

    if (!props.solid) {
      const breakPoint = 200
      const footerBreakPoint = 1200
      window.onscroll = (): void => {
        if (window.scrollY > breakPoint) {
          setSolidBG(true)
        } else {
          setSolidBG(false)
        }

        if (window.scrollY > footerBreakPoint) {
          if (bs1) {
            bs1.classList.replace('slide-in-bottom', 'slide-out-bottom')
          }
          if (bs2) {
            bs2.classList.replace('slide-in-bottom', 'slide-out-bottom')
          }
        } else {
          if (bs1) {
            bs1.classList.replace('slide-out-bottom', 'slide-in-bottom')
            bs1.classList.replace('base-menu', 'slide-in-bottom')
          }
          if (bs2) {
            bs2.classList.replace('slide-out-bottom', 'slide-in-bottom')
            bs2.classList.replace('base-menu', 'slide-in-bottom')
          }
        }
      }
    } else {
      setSolidBG(true)
    }
  }

  useEffect(() => {
    watchScroll()
  }, [])

  interface Links {
    name: string
    url: string
    type?: string
  }

  const navLinks = [
    {
      id: 1,
      name: 'Home',
      url: '/'
    },
    {
      id: 2,
      name: 'Our Properties',
      url: '/properties'
    },
    {
      id: 3,
      name: 'Destinations',
      url: '/destinations'
    },
    {
      id: 4,
      name: 'News and Events',
      url: '/news-and-events'
    },
    {
      id: 5,
      name: 'Homebuying Guide',
      url: '/homebuying-guide'
    },
    {
      id: 6,
      name: 'Sellers Home',
      url: '/sellers-home'
    },
    {
      id: 7,
      name: 'About us',
      url: '/about-us'
    },
    {
      id: 8,
      name: 'Careers',
      url: '/careers'
    }
  ]

  const [menu, setMenu] = useState<boolean>(false)

  const toggleMenu = (): void => {
    setMenu(!menu)
  }

  return (
    <>
      <div className={`header ${solidBG ? 'solid' : props.noData ? 'solid' : ''}`}>
        <Link to="/" className="logo-link">
          <Logo fill="#fff" size={70} id="header-logo" />
        </Link>

        <div className="nav-wrapper">
          <div className="nav-items" id="mid-nav">
            <div className="menu-links">
              {navLinks &&
                navLinks.slice(1, 5).map((item: Links) => {
                  return (
                    <div key={Math.random()}>
                      {props.activeLink === item.name && <div className="active-bar fade-in dl-2" />}
                      <a href={item.url} className={`${props.activeLink === item.name ? 'active' : ''}`}>
                        {item.name}
                      </a>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>

        <div className="nav-wrapper">
          <div className="nav-items">
            <a href="/search">
              <img src={searchIcon} alt="search icon" className="svg-icon" />
              <span className="nav-text">search</span>
            </a>
            <a href="#menu" onClick={toggleMenu}>
              <img src={burger} alt="burger icon" className="svg-icon" />
              <span className="nav-text">menu</span>
            </a>
          </div>
        </div>
      </div>

      {menu && (
        <>
          <div className="nav-menu-wrapper fade-in" role="button" onClick={toggleMenu} />
          <div className="menu-sidebar slide-in-right">
            <i className="la la-close close-btn la-2x" onClick={toggleMenu} />
            <div className="menu-links">
              {navLinks &&
                navLinks.map((item: Links) => {
                  return (
                    <a href={item.url} key={Math.random()}>
                      <div>
                        <span>{item.name}</span>
                        {item.type ? <i className="la la-angle-down" /> : ''}
                      </div>
                    </a>
                  )
                })}
            </div>

            <div className="menu-footer">
              <div className="menu-footer-content">
                <div className="social-links">
                  {socialLinks &&
                    socialLinks.map((item: { link: string; name: string }) => {
                      if (item.name !== '') {
                        return (
                          <a href={item.link} target="_blank" rel="noreferrer" key={Math.random()} title={item.link}>
                            <i className={`la la-${item.name.toLowerCase()}`} />
                          </a>
                        )
                      }
                    })}
                </div>

                <div className="footer-credits">
                  <small>Powered By:</small>
                  <a href="https://whitecloak.com" target="_blank" rel="noreferrer">
                    White Cloak Technologies Inc.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Header
