import React, { ReactElement } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Layout.scss'
import './Animations.scss'

type LayoutPropTypes = {
  noData?: boolean
  children?: string | Element | ReactElement | boolean | (string | Element | ReactElement | boolean)[]
}

const NewsAndEventsLayout = ({ children, noData }: LayoutPropTypes): ReactElement => (
  <>
    <Header activeLink="News and Events" noData={noData} />
    {children}
    <Footer />
  </>
)

export default NewsAndEventsLayout
