import * as React from 'react'

function DiscoverArrow(): JSX.Element {
  return (
    <svg width={17} height={12} viewBox="0 0 17 12">
      <path
        d="M11.063.525a.58.58 0 00-.828 0 .584.584 0 000 .82l4.199 4.198H.58a.577.577 0 00-.58.58c0 .324.257.589.58.589h13.854l-4.199 4.19a.594.594 0 000 .828.58.58 0 00.828 0l5.193-5.192a.57.57 0 000-.82L11.063.525z"
        fill="#FFF"
        fillRule="nonzero"
        fillOpacity={0.7}
      />
    </svg>
  )
}

export default DiscoverArrow
