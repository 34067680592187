import { Link } from 'gatsby'
import React from 'react'

import './NewsAndEvents.scss'

import detailsImg1 from '../../../assets/images/destinations-details/details1.png'
import detailsImg2 from '../../../assets/images/destinations-details/details2.png'
import { FeaturedData } from '../data'

interface ContentTypes {
  component: number
  image: string
  title: string
  title1: string
  body: string
}

const NewsAndEventsDetails = (props: any): JSX.Element => {
  const { data, contentImages, featured, mediaImages } = props
  const { content } = data
  const contentImageArray: any[] = []
  const imageIndex = 0

  const newsAndEventsDetailsContent =
    content.length !== 0 &&
    content.map((resContent: ContentTypes) => {
      const contentImage = resContent.image
      const contenttitle = resContent.title
      const contenttitle1 = resContent.title1
      const contentBody = resContent.body
      const contentComponent = resContent.component

      return (
        <>
          {contentComponent === 14 && contentImage ? (
            <a href={contenttitle1} rel="noreferrer" target="_blank">
              <img src={contentImage} alt="content" />
            </a>
          ) : (
            contentImage && <img src={contentImage} alt="content" />
          )}
          {contenttitle && <h3>{contenttitle}</h3>}
          {contentComponent === 14 && contentBody ? (
            <div dangerouslySetInnerHTML={{ __html: contentBody }} />
          ) : (
            contentBody && <p dangerouslySetInnerHTML={{ __html: contentBody }} />
          )}
        </>
      )
    })

  return (
    <div className="news-and-events-details-page-container">
      <div className="news-and-events-details-page-wrapper">
        <div className="news-and-events-details-content-paper fade-in-bottom">
          <p dangerouslySetInnerHTML={{ __html: data.description }} />
          {newsAndEventsDetailsContent}
        </div>

        <div className="news-and-events-details-others">
          {featured && featured.length === 1 && featured[0].node.context.data.id === data.id ? null : <h3>Other Articles</h3>}

          {featured &&
            // eslint-disable-next-line array-callback-return, consistent-return
            featured.map((feature: { node: FeaturedData }, index: number) => {
              const dataFeature = feature.node

              if (data.id !== dataFeature.context.data.id) {
                return (
                  <Link key={dataFeature.context.data.title} to={dataFeature.path ? dataFeature.path : ''}>
                    <div className="other-card fade-in-bottom">
                      <img src={dataFeature.context.data.featuredMedia[0].media} alt="" />
                      <div>
                        <h4>{dataFeature.context.data.title}</h4>
                        <span>{dataFeature.context.data.updated}</span>
                      </div>
                    </div>
                  </Link>
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}

export default NewsAndEventsDetails
