import { graphql, useStaticQuery, navigate } from 'gatsby'
import React, { ReactElement, useState, useEffect } from 'react'

import NewsAndEventsLayout from '../../components/Layout/NewsAndEvents'
import Hero from '../../components/NewsAndEventsPage/Hero'
import NewsAndEventsDetails from '../../components/NewsAndEventsPage/NewsAndEventsDetails'
import MenuBaseDetails from '../../components/MenuBaseDetails'
import SEO from '../../components/SEO/SEO'

import { NEWS_AND_EVENTS, NewsAndEventsTypes } from '../../components/NewsAndEventsPage/data'

const NewsAndEventsPage = ({ pageContext: { data } }: any): ReactElement => {
  const allNewsAndEventPage = useStaticQuery(graphql`
    query allNewsAndEvents {
      allSitePage(filter: { context: { category: { eq: "news-and-events" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                estate
                description
                title
                updated
                headingTag
                seoTitle
                seoDescription
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const allNewsAndEvents = allNewsAndEventPage.allSitePage.edges

  const indexNewsAndEvents = (): number => {
    let tempIndex = 0
    allNewsAndEvents.map((resNewsAndEvent: any, indexNewsAndEvent: number) =>
      resNewsAndEvent.node.context.data.id === data.id ? (tempIndex = indexNewsAndEvent) : null
    )
    return tempIndex
  }

  const featured = allNewsAndEvents.filter((resFeatured: any) => resFeatured.node.context.data.feature === true)
  const [index, setIndex] = useState(indexNewsAndEvents)

  const indexCount = index + 1
  const prevIndex = index === 0 ? allNewsAndEvents.length - 1 : index - 1
  const nextIndex = indexCount === allNewsAndEvents.length ? 0 : indexCount

  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  return (
    <>
      <NewsAndEventsLayout>
        <SEO seoTitle={data.seoTitle} seoDescription={data.seoDescription} title={`${data.type} - ALISI | Ayala Land`} />
        <Hero data={{ context: { data } }} detail={true} />
        <h1 style={{ display: 'none' }}>{data.headingTag}</h1>
        <NewsAndEventsDetails featured={featured} data={data} />
      </NewsAndEventsLayout>
      <MenuBaseDetails
        title={data.title}
        prevTitle={allNewsAndEvents[prevIndex].node.context.data.title}
        prevOnClick={() => navigate(allNewsAndEvents[prevIndex].node.path)}
        nextTitle={allNewsAndEvents[nextIndex].node.context.data.title}
        nextOnClick={() => navigate(allNewsAndEvents[nextIndex].node.path)}
        url={data.slug}
        count={allNewsAndEvents.length}
      />
    </>
  )
}

export default NewsAndEventsPage
