import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width={15} height={15} viewBox="0 0 15 15" {...props}>
      <path
        d="M9.303 5.756a3.558 3.558 0 010 5.014l-3.194 3.196a3.556 3.556 0 01-5.015 0l-.06-.06a3.555 3.555 0 010-5.014l2.237-2.233a4.95 4.95 0 00.358 1.938L2.184 10.04a1.922 1.922 0 000 2.715l.06.06a1.923 1.923 0 002.715 0L8.155 9.62a1.922 1.922 0 000-2.716l-.061-.058a1.847 1.847 0 00-.383-.294l1.17-1.169c.125.095.248.199.362.314l.06.058zm4.664-4.662l-.06-.06a3.556 3.556 0 00-5.014 0L5.697 4.23a3.555 3.555 0 000 5.014l.06.058c.114.113.238.218.362.314l1.17-1.17a1.991 1.991 0 01-.383-.293l-.06-.058a1.924 1.924 0 010-2.717l3.196-3.194a1.92 1.92 0 012.714 0l.06.06a1.922 1.922 0 010 2.715l-1.443 1.444c.25.62.368 1.28.358 1.938l2.234-2.235a3.555 3.555 0 00.002-5.012z"
        fill="#157B58"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
