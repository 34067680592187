/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react'
import './MenuBaseDetails.scss'

import { FacebookShareButton, EmailShareButton, TwitterShareButton } from 'react-share'
import CopyToClipboard from 'react-copy-to-clipboard'
import MailIcon from '../../assets/icons/MailIcon'
import ChainIcon from '../../assets/icons/Chain'

interface PropsTypes {
  title?: string
  prevTitle?: string
  prevOnClick?: any
  nextTitle?: string
  nextOnClick?: any
  url: string
  count: number
}

const MenuBaseDetails = (props: PropsTypes): JSX.Element => {
  const [prevTitle, setPrevTitle] = useState(false)
  const [nextTitle, setNextTitle] = useState(false)
  const [copied, setCopied] = useState(false)

  const [url, setURL] = useState<string>('')

  useEffect(() => {
    setURL(window.location.href)
  }, [])

  return (
    <div className="menu-base-details base-menu">
      {props.count > 1 && (
        <div
          className="arrow left"
          onMouseOver={(): void => setPrevTitle(true)}
          onMouseLeave={(): void => setPrevTitle(false)}
          onClick={props.prevOnClick}
        >
          <i className="la la-angle-left" />
          <span>PREVIOUS</span>
        </div>
      )}

      {prevTitle && (
        <>
          <div className="title-hover left fade-in">
            <div>{props.prevTitle}</div>
          </div>
          <div className="tail left fade-in dl-5" />
        </>
      )}

      <div className="menu-group">
        <div className="menu-title">
          <span>YOU ARE READING</span>
          <h3>{props.title}</h3>
        </div>
        <div className="menu-share">
          {copied ? (
            <>
              <div className="share-icon-container copied fade-in-bottom">
                <span>Copied to clipboard!</span>
              </div>
              <div className="icon-container" onClick={(): void => setCopied(false)}>
                <i className="la la-close" />
              </div>
            </>
          ) : (
            <>
              <span>Share</span>

              <FacebookShareButton url={url}>
                <div className="icon-container">
                  <i className="la la-facebook" />
                </div>
              </FacebookShareButton>

              <TwitterShareButton url={url}>
                <div className="icon-container">
                  <i className="la la-twitter" />
                </div>
              </TwitterShareButton>

              <EmailShareButton url={url}>
                <div className="icon-container">
                  <MailIcon />
                </div>
              </EmailShareButton>

              <CopyToClipboard text={url} onCopy={(): void => setCopied(true)}>
                <div className="share-icon-container">
                  <ChainIcon />
                </div>
              </CopyToClipboard>
            </>
          )}
        </div>
      </div>

      {props.count > 1 && (
        <div
          className="arrow right"
          onMouseOver={(): void => setNextTitle(true)}
          onMouseLeave={(): void => setNextTitle(false)}
          onClick={props.nextOnClick}
        >
          <span>NEXT</span>
          <i className="la la-angle-right" />
        </div>
      )}

      {nextTitle && (
        <>
          <div className="title-hover right fade-in">
            <div>{props.nextTitle}</div>
          </div>
          <div className="tail right fade-in dl-5" />
        </>
      )}
    </div>
  )
}

export default MenuBaseDetails
